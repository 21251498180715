@media only screen and (max-width: 800px) {
    .collection-bg {
        display: none !important;
    }
}

@media only screen and (min-width: 801px) {
    .collection-sm {
        display: none !important;
    }
}